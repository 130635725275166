import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';

const PhoneSection = ({ setLoading }) => {

    const { t } = useTranslation("home");

    useEffect(() => {
        const image = new Image();
        image.src = "/assets/bg/fondo.svg";
        image.onload = () => setTimeout(() => {
            setLoading(false);
        }, 1000);;
    }, [setLoading]);
 
    return (
        <div className="relative flex lg:flex-row lg:items-center flex-col items-start justify-center h-auto bg-cover bg-center" style={{ backgroundImage: "url(/assets/bg/fondo.svg)" }}>

            {/* Contenedor principal para el contenido */}
            <div className="z-10 flex flex-col justify-center text-center lg:text-left px-6 lg:px-32 h-full w-full lg:w-[100%] mb-10">

                {/* <h1 className="text-3xl lg:text-5xl font-bold text-white mt-5">
                    ¡MUCHO MÁS QUE UNA SIMPLE APP, ES <span className="text-[#ffc300]">TU COMPAÑERO DE ENTRENAMIENTO!</span>
                </h1> */}

                <h1 className="text-3xl lg:text-5xl font-bold text-white mt-5">
                    <Typewriter
                        options={{
                            strings: [
                                "DEPORTSEN",
                            ],
                            autoStart: true,
                            loop: false,
                            cursor: "|",
                            delay: 75,
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .typeString("¡MUCHO MÁS QUE UNA SIMPLE APP,")
                                .pauseFor(200)
                                .typeString("<span class='text-[#ffc300]'> ES TU COMPAÑERO DE ENTRENAMIENTO!</span>")
                                .start();
                        }}
                    />
                </h1>

                <div className="bg-black bg-opacity-50 p-8 rounded-lg text-white mt-5">
                    <p className="mt-4 text-lg text-white">
                        Prepárate para el viaje hacia la mejora del fútbol con una app diseñada para aumentar tu rendimiento y maximizar tu potencial en el campo.
                    </p>
                </div>

            </div>

            {/* Imagen de la mano con el teléfono */}
            <div className="relative w-auto mt-5 mb-5 w-full flex justify-end">
                <img
                    src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasAppV4.1.0%2Fpng%2FDEPORTSEN.png?alt=media&token=b4df256a-e082-4599-a70e-72ed21b3676f"}
                    alt="Mano con teléfono"
                    className="object-contain h-auto w-full md:w-[35vw] lg:w-[35vw]"
                />
            </div>

        </div>
    );
};

export default PhoneSection;
