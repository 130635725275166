import React, { useState } from 'react';
import logoImage from '../../assets/logoDeportsen.png';
import parametros from '../parametros/parametros.json';
import { Link } from 'react-router-dom';
import { IoLanguageOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';

const Header = () => {

    const { t, i18n } = useTranslation("header");

    const navigate = useNavigate();
    const location = useLocation();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsDropdownOpen(false);

        const currentPath = location.pathname;
        const newPath = currentPath.replace(/\/[a-z]{2}(\/|$)/, `/${lng}$1`);
        navigate(newPath, { replace: true });
    };

    return (
        <header className="bg-gray-800 text-white p-4 flex flex-col" style={{ backgroundColor: parametros.backGroundColor_azul_oscuro }}>

            <div className='flex flex-row justify-between items-center lg:px-20 md:px-8 sm:px-4 px-4 w-full'>

                <div className="flex flex-row items-center justify-center">

                    <Link to={`/home/${i18n.language}`} >
                        <img src={logoImage} alt="Logo deportsen" className="h-12 w-12" />
                    </Link>
                    <Link to={`/home/${i18n.language}`} >
                        <h1 className="text-[#ffc300] ml-2.5">DEPORTSEN</h1>
                    </Link>
                </div>

                <div className="block md:hidden flex items-center">
                    <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} />
                </div>

                <div className="hidden md:flex items-center">

                    <Link to={`/home/${i18n.language}`} className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_home")}</Link>

                    <Link to={`/about-us/${i18n.language}`} className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_header_about")}</Link>

                    <div className="relative">

                        <div className="flex items-center">
                            <span className="mr-2">{t("text_language")}</span>
                            <IoLanguageOutline onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="text-xl cursor-pointer hover:text-gray-500 transition-colors" />
                        </div>

                        {isDropdownOpen && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="absolute right-0 mt-2 w-40 bg-white text-black rounded-md shadow-lg z-10"
                                style={{ zIndex: '10000' }}
                            >
                                <ul>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('es')}>{t("language_es")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('de')}>{t("language_de")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('fr')}>{t("language_fr")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('hi')}>{t("language_hi")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('en')}>{t("language_en")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('it')}>{t("language_it")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('pt')}>{t("language_pt")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('ru')}>{t("language_ru")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('tr')}>{t("language_tr")}</li>
                                    <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('uk')}>{t("language_uk")}</li>
                                </ul>
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>


            <div className="flex items-center justify-center lg:px-20 md:px-8 sm:px-4 px-4 w-full">
                {/* Agregamos animación de apertura/cierre */}
                <AnimatePresence>
                    {isMenuOpen &&
                        <motion.div
                            key="menu" // Clave única para el menú
                            initial={{ opacity: 0, height: 0 }}   // Comienza oculto
                            animate={{ opacity: 1, height: 'auto' }}  // Expande al tamaño completo
                            exit={{ opacity: 0, height: 0 }}      // Se colapsa lentamente
                            transition={{
                                opacity: { duration: 0.3 },      // Duración de la opacidad
                                height: { duration: 0.5 },       // Duración de la altura
                            }}    // Duración de la animación
                            className="block md:hidden w-full"
                        >
                            <div className="flex flex-col items-start justify-start w-full">
                                <Link to={`/home/${i18n.language}`} className="mt-4 text-white hover:text-gray-500 transition-colors">{t("text_home")}</Link>
                                <Link to={`/about-us/${i18n.language}`} className="mt-4 text-white hover:text-gray-500 transition-colors">{t("text_header_about")}</Link>

                                <div className="relative mt-4">
                                    <div className="flex items-center">
                                        <span className="mr-2">{t("text_language")}</span>
                                        <IoLanguageOutline onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="text-xl cursor-pointer hover:text-gray-500 transition-colors" />
                                    </div>

                                    {isDropdownOpen && (
                                        <motion.div
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            className="absolute left-0 mt-2 w-40 bg-white text-black rounded-md shadow-lg z-10"
                                            style={{ zIndex: '10000' }}
                                        >
                                            <ul>
                                                {/* Aquí los links para cambiar de idioma */}
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('es')}>{t("language_es")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('de')}>{t("language_de")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('fr')}>{t("language_fr")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('hi')}>{t("language_hi")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('en')}>{t("language_en")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('it')}>{t("language_it")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('pt')}>{t("language_pt")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('ru')}>{t("language_ru")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('tr')}>{t("language_tr")}</li>
                                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('uk')}>{t("language_uk")}</li>
                                            </ul>
                                        </motion.div>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>


        </header>
    );
};

export default Header;
